<template>
<div v-if="wallet.connected" class="mt-4 py-4 max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 bg-white border-2 rounded-md">

<div class="px-0">
  <div class="flex flex-col">
    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6 md:pl-0">Period</th>
              <th scope="col" class="py-3.5 px-3 text-left text-sm font-semibold text-gray-500">Price (ETH)</th>
              <th scope="col" class="py-3.5 px-3 text-left text-sm font-semibold text-gray-500">Minted</th>
              <th scope="col" class="py-3.5 px-3 text-left text-sm font-semibold text-gray-500">Period Start (UTC)</th>
              <th scope="col" class="py-3.5 px-3 text-left text-sm font-semibold text-gray-500">Period End (UTC)</th>
              <th scope="col" class="py-3.5 px-3 text-left text-sm font-semibold text-gray-500"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="">
              <td class="pt-4 whitespace-nowrap py-1 pl-4 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">Current</td>
              <td class="pt-4 whitespace-nowrap py-1 px-3 text-sm font-medium text-gray-900">{{ currentPeriodPrice() }}</td>
              <td v-if="currentPeriodCanMint()" 
                class="whitespace-nowrap pt-4 py-1 px-3 text-sm font-medium text-gray-900"
                >{{ wallet.w_status.mintedToday }}/{{ wallet.w_status.mintsPerDay }}
              </td>
              <td v-else class="whitespace-nowrap pt-4 py-1 px-3 text-sm font-medium text-red-700"
                >{{ wallet.w_status.mintedToday }}/{{ wallet.w_status.mintsPerDay }}
              </td>
              <td class="whitespace-nowrap pt-4 py-1 px-3 text-sm font-medium text-gray-900">{{ currentPeriodStart() }}</td>
              <td class="whitespace-nowrap pt-4 py-1 px-3 text-sm font-medium text-gray-900">{{ currentPeriodEnd() }}</td>
                <td v-if="currentPeriodCanMint()"
                    class="whitespace-nowrap pt-4 py-1 px-3 text-sm font-medium text-gray-900">
                    <button class="bg-gray-900 text-white px-3 pt-2 py-2 rounded-md text-xs"  @click="wallet.w_mint">Mint</button>
                </td>
                <td v-else
                    class="whitespace-nowrap pt-4 py-1 px-3 text-sm font-medium text-gray-900">
                    <button class="bg-gray-500 text-white px-3 py-2 rounded-md text-xs">No more mints available</button>
                </td>
            </tr>

            <tr>
              <td class="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6 md:pl-0">Next</td>
              <td class="whitespace-nowrap py-1 px-3 text-sm text-gray-500">{{ nextPeriodPrice() }}</td>
              <td class="whitespace-nowrap py-1 px-3 text-sm text-gray-500">0/{{ wallet.w_status.mintsPerDay }}</td>
              <td class="whitespace-nowrap py-1 px-3 text-sm text-gray-500">{{ nextPeriodStart() }}</td>
              <td class="whitespace-nowrap py-1 px-3 text-sm text-gray-500">{{ nextPeriodEnd() }}</td>
              
              <td v-if="!currentPeriodCanMint()"
                class="whitespace-nowrap pt-0 py-1 px-3 text-sm font-medium text-gray-900">
                <button class="bg-gray-900 text-white px-3 pt-2 py-2 rounded-md text-xs" @click="wallet.w_mint">Mint</button>
              </td>
            </tr>

            <!-- More people... -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>



<TxList :txDict="wallet.tx" :maxItems=8 />
<GalleryItems :txDict="wallet.tx" :maxItems=16 />


</div>
</template>


<script setup>
    import dayjs from 'dayjs'
    import { useWalletStore2 } from '@/stores/WalletStore2'
    const wallet = useWalletStore2()
    wallet.init()

    import TxList from '@/components/TxList.vue'
    import GalleryItems from '@/components/GalleryItems.vue'

    function tsToDate(ts) {
        const d = dayjs.unix(ts)
        return d.format('YYYY-MM-DD HH:mm')
    }

    function currentPeriodPrice() {
        return (wallet.w_status.price_eth*1).toFixed(3)
    }

    function currentPeriodCanMint() {
        //console.log('Period start', wallet.w_status.mintPeriodStart)
        if (
            (wallet.w_status.mintedToday < wallet.w_status.mintsPerDay) &&
            ( (wallet.w_status.mintPeriodStart+60*60*24)*1000 > Date.now() )
        ) {
            return true
        } else {
            return false
        }
    }

    function currentPeriodStart() {
        const ts = wallet.w_status.mintPeriodStart
        if (!ts) {
            return 'With next mint'
        } else {
            return tsToDate(ts)
        }
    }
    function currentPeriodEnd() {
        const ts = wallet.w_status.mintPeriodStart
        if (!ts) {
            return '24h after next mint'
        } else {
            return tsToDate(ts + 60*60*24)
        }
    }

    function nextPeriodStart() {
        const ts = wallet.w_status.mintPeriodStart
        //const now = Date.now() / 1000
        if (!ts) {
            return '-'
        } else {
            return tsToDate(ts + 60*60*24)
        }
    }
    function nextPeriodEnd() {
        const ts = wallet.w_status.mintPeriodStart
        //const now = Date.now() / 1000
        if (!ts) {
            return '-'
        } else {
            return tsToDate(ts + 60*60*24*2)
        }
    }
    function nextPeriodPrice() {
        const p = wallet.w_status.price_eth
        if (wallet.w_status.mintedToday == wallet.w_status.mintsPerDay) {
            return (p*1.1).toFixed(3)
        }
        if ( wallet.w_status.mintedToday > 2 ) {
            return (p*1).toFixed(3) + " - " + (p*1.1).toFixed(3)
        }
        return (p*0.9).toFixed(3) + " - " + (p*1.1).toFixed(3)
    }

</script>


<style>
</style>