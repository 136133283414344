import { createApp } from 'vue';
import Toast, { PluginOptions } from "vue-toastification";

import App from '@/App.vue';
import { createPinia } from 'pinia';
import router from '@/router'

import "vue-toastification/dist/index.css";
import '@/styles/app.css';

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(Toast);
app.mount('#app')