<template>
<div class="py-0 grid gap-4">
  <div class="w-full h-screen overflow-hidden homepage-image flex"
  :style="'background-image: url(' + coverImage() + ');' "
  > 
  <div class="text-8xl m-auto">
    <a href="#about-section"
          class="m-2 inline-flex items-center justify-center p-5 border-4 border-white text-2xl sm:text-3xl font-bold rounded-xl 
          text-white bg-gray-900/80 no-underline"
          >What's this?
      </a>
    <router-link to="/mint/" 
          class="m-2 inline-flex items-center justify-center p-5 border-4 border-white text-2xl sm:text-3xl font-bold rounded-xl 
          text-white bg-gray-900/80 no-underline"
          >Mint <ArrowNarrowRightIcon class="pl-1 h-8 w-8 inline-block"  />
      </router-link>
  </div>
  </div>
  <div class="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8" id="about-section">
    <div class="lg:text-left  safe-notch" >
      <p class="mt-2 text-5xl lg:text-8xl leading-8 tracking-tight font-bold text-gray-900">
      NiftyWalls</p>
      <p class="mt-4 mb-8 pt-4 text-3xl text-gray-500 lg:mx-auto">
      A collection of 8192 algorithmically generated NFT wallpapers. <br/>And some more.</p>
    </div>

    <div class="homepage safe-notch">
      <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10">
        <div class="">
          <div class="homepage-title">
              <CheckCircleIcon class="mb-2 h-8 w-8 inline-block" />
              Beautiful artwork
          </div>
          <div class="homepage-text">
            Each Wall is based on a algorithmically generated "turmite pattern" (see <a href="https://turmites.art/">turmites.art</a>) that was then colored using a predefined set of color palettes and distorted
            using a kaledoscopic effect. The result is a beautiful and interesting
            collection of 8192, 3000x3000 wallpapers.
          </div>
        </div>

        <div class="">
          <dt class="homepage-title">
              <CheckCircleIcon class="mb-2 h-8 w-8 inline-block" />
              0% royalties.
          </dt>
          <dd class="homepage-text">
            
          </dd>
        </div>

        <div class="">
          <dt class="homepage-title">
              <CheckCircleIcon class="mb-2 h-8 w-8 inline-block" />
              Creative Commons License.
          </dt>
          <dd class="homepage-text">
            Walls are distributed under the CC-BY license: you are free to use them in derivative works or commercially, 
            provided you give attribution to the NiftyWalls project.
          </dd>
        </div>

        <div class="">
          <dt class="homepage-title">
            <CheckCircleIcon class="mb-2 h-8 w-8 inline-block" />
            Random assignment at mint
          </dt>
          <dd class="homepage-text">
            No pre-defined order. Every time a Wall is minted, the contract picks a random one.
          </dd>
        </div>

        <div class="">
          <dt class="homepage-title">
              <CheckCircleIcon class="mb-2 h-8 w-8 inline-block" />
              A long term project &mdash;by definition.
          </dt>
          <dd class="homepage-text">
            Only up to 8 Walls can be minted every 24h hours. 
            It will take nearly 3 years to mint the whole collection.
            This brings fairness (everyone has a chance to mint, no priviledged alpha),
            and enforces long term commitment from the developer.
          </dd>
        </div>

        <div class="">
          <dt class="homepage-title">
              <CheckCircleIcon class="mb-2 h-8 w-8 inline-block" />
              On-chain metadata. Long-term storage for artwork.
          </dt>
          <dd class="homepage-text">
            NiftyWall metadata is stored on the Ethereum blockchain. 
            The artwork is stored on <a href="https://www.arweave.org/">arweave</a>, a long-term storage 
            solution designed to last for ever.
          </dd>
        </div>

        <div class="">
          <dt class="homepage-title">
              <CheckCircleIcon class="mb-2 h-8 w-8 inline-block" />
              Dynamic mint pricing, based on demand.
          </dt>
          <dd class="homepage-text">
            The initial mint price is Ξ0.05. Every 24h the price is adjusted based on demand: 
            If only 1 or 2 Walls were minted during the previous period, the price drops by 10%, 
            if all 8 Walls were minted in that period, the price goes up 10%.
          </dd>
        </div>

        <div class="">
          <dt class="homepage-title">
              <HeartIcon class="mb-2 h-8 w-8 inline-block fill-red-500" />
              Our NFT friends get free mints.
          </dt>
          <dd class="homepage-text">
            No NFT project is an island. Many of the ideas behind NiftyWalls came from other projects, like PunkScapes.
            As a token of gratitude for being such an interesting and inclusive community, any wallet holding a PunkScape 
            can mint a Wall for free.
            The smart contract allows more NFT contracts to be added as friends who get free mints. Looking forward to making new friends in the space! WAGMI together.
          </dd>
        </div>

      </dl>
    </div>
  </div>
</div>
<MintNow />
</template>

<script setup>
  import MintNow from './MintNow.vue'
  import { GlobeAltIcon, ScaleIcon, ClockIcon, GiftIcon, AnnotationIcon} from '@heroicons/vue/outline'
  import { HeartIcon, CheckCircleIcon, ArrowNarrowRightIcon } from '@heroicons/vue/solid'

  function coverImage() {
    const x = (Math.floor(Math.random()*8192)+1)
    return 'https://niftywalls.s3.amazonaws.com/wall/' + x + '.png'
  }

  function thumbnails() {
    let ret = []
    for(let i=0; i<20; i++) {
      ret.push(Math.floor(Math.random()*8192)+1)
    }
    return ret
  }
</script>