<template>
  <NavigationBar />

<div class="sm:bt-5 pt-16 lg:pt-32 pb-8 bg-gray-100">
  <div class="mx-auto px-4 sm:py-16 sm:px-6 lg:max-w-7xl lg:px-8">
    <!-- Product -->
    <div class="lg:grid lg:grid-rows-1 lg:grid-cols-7 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
      <!-- Product image -->
      <div class="lg:row-end-1 lg:col-span-3 max-w-xl mx-auto pt-4 lg:pt-0">
        <div class="rounded-lg bg-gray-100 overflow-hidden">
          <img :src="'https://niftywalls.s3.amazonaws.com/' + view + '/' + id + '.png'" alt="Scaled down version of the original Wall" class="object-center object-cover w-full">
        </div>
         <p class="text-gray-900 mt-6 text-sm font-normal">
          <button @click="view='thumbnails-600'" class="mx-2"
          :class="(view=='thumbnails-600') ? 'decoration-solid underline underline-offset-4': ''"
          >
              <PhotographIcon class="h-6 w-6 stroke-gray-900 inline-block" />Preview 600x600
          </button>
          <button @click="view='crops'" class="mx-2"
            :class="(view=='crops') ? 'decoration-solid underline underline-offset-4': ''"
          >
            <SearchCircleIcon class="h-6 w-6 stroke-gray-900 inline-block" />View detail
          </button>
          <a target="_new" :href="'https://niftywalls.s3.amazonaws.com/wall/' + id + '.png'" class="text-gray-900 mx-2">
            <DownloadIcon class="h-6 w-6 stroke-gray-900 inline-block" />Download 3000x3000
          </a>
        </p>

        <p class="text-gray-900 mt-6 text-sm font-normal">
          <a target="_new" :href="'https://niftywalls.s3.amazonaws.com/opensea-banner/' + id + '.png'" class="text-gray-900 mx-2">
            <DownloadIcon class="h-6 w-6 stroke-gray-900 inline-block" />OpenSea banner (optimized)
          </a>
          </p>
      </div>

      <!-- Product details -->
      <div class="max-w-xl mx-auto mt-14 sm:mt-16 lg:max-w-none lg:mt-0 lg:row-end-2 lg:row-span-2 lg:col-span-3">
        <div class="flex flex-col-reverse">
          <div class="mt-0">
            <h1 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              NiftyWall #{{ id }}
            </h1>
          </div>
        </div>
        <p class="text-gray-500 mt-6">
        Each Wall is based on a algorithmically generated "turmite pattern" which is then colored and transformed 
        using a kaledoscopic effect, generating a beautiful and interesting 3000x3000 pattern.
        </p>
        <p>You can use Walls as wallpapers, crop them and use them as profile banners, or in any other way you like: 
          NiftyWalls are distributed under the Creative Commons Attribution License.
        </p>


        <div class="border-t border-gray-200 mt-2 pt-4">
          <h3 class="text-lg font-medium text-gray-900">Marketplaces
          <span v-if="wallet.connected">
            <div v-if="wall_owner" class="inline-block">
               <a  
               :href="'https://opensea.io/assets/0x1718af1613d86e21488a9d985a586ae9c5dbd62e/'+id" class="no-underline"
               target="_new" ><img :src="OpenSeaLogo" alt="opensea-logo"  class="h-6 w-6 stroke-gray-900 inline-block pb-1"/></a>
               <a  
               :href="'https://looksrare.org/collections/0x1718aF1613d86e21488A9D985a586AE9c5dBD62E/'+id" class="no-underline"
               target="_new" ><img :src="LooksRareLogo" alt="looksrare-logo"  class="h-6 w-6 stroke-gray-900 inline-block pb-1"/></a>
               <a  
               :href="'https://x2y2.io/eth/0x1718aF1613d86e21488A9D985a586AE9c5dBD62E/'+id" class="no-underline"
               target="_new" ><img :src="X2Y2Logo" alt="looksrare-logo"  class="h-6 w-6 stroke-gray-900 inline-block pb-1"/></a>
               <a  
               :href="'https://nft.coinbase.com/nft/ethereum/0x1718af1613d86e21488a9d985a586ae9c5dbd62e/'+id" class="no-underline"
               target="_new" ><img :src="CoinbaseLogo" alt="coinbase-logo"  class="h-6 w-6 stroke-gray-900 inline-block pb-1"/></a>
            </div>
            <div v-else class="text-gray-400 inline-block">Wall is not minted yet and does not appear on marketplaces.</div>
          </span>
          <span v-else class="text-gray-400">Connect your wallet. Only minted items appear on marketplaces, so this 
            information needs to be retrieved from the blockchain. 
          </span>
         </h3>
      </div>


        <div class="border-t border-gray-200 mt-2 pt-4">
          <h3 class="text-lg font-medium text-gray-900">Traits</h3>
          <div v-for="t in wallMetadata(id).attributes" class="inline-block align-middle">
            <div class="inline-block border border-gray-300 rounded-md ml-0 mr-2 my-2 py-3 px-3 w-min">
            <div class="text-sm text-gray-400 flex align-left">{{ t.trait_type }}</div>
            <div class="text-lg text-black inline-flex items-center justify-middle whitespace-nowrap block">
            
              <svg v-if="( (t.trait_type == 'Color') || (t.trait_type == 'Background') ) && t.value != 'None'"
                class="mr-1 w-4 h-4">
                  <rect width="100%" height="100%"
                  :style="'fill:#'+t.value +';stroke:grey;stroke-width:2;opacity:1'" />
              </svg>
            {{ t.value }}</div>

            </div>
          </div>

        </div>

        <div class="border-t border-gray-200 mt-2 pt-4">
          <h3 class="text-lg font-medium text-gray-900">Licensed under CC BY 4.0</h3>

          <p class="mt-4 text-lg text-gray-500">This license requires that reusers give credit to <span class="text-gray-900">"NiftyWalls.xyz"</span>. It allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, even for commercial purposes. <a href="https://creativecommons.org/licenses/by/4.0/">Read the full license</a>.</p>
           
        </div>

        <div v-if="false" class="border-t border-gray-200 mt-10 pt-10">
          <h3 class="text-sm font-medium text-gray-900">Share</h3>
          <ul role="list" class="flex items-center space-x-6 mt-4">
            <li>
              <a href="#" class="flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500">
                <span class="sr-only">Share on Facebook</span>
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                  <path fill-rule="evenodd" d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z" clip-rule="evenodd" />
                </svg>
              </a>
            </li>
            <li>
              <a href="#" class="flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500">
                <span class="sr-only">Share on Instagram</span>
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
                </svg>
              </a>
            </li>
            <li>
                <svg class="w-4 h-4 inline-block" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                </svg>
                <span class="no-underline text-gray-400 text-sm">Share on Twitter</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import OpenSeaLogo from "@/assets/opensea.svg"
    import LooksRareLogo from "@/assets/looksrare.svg"
    import X2Y2Logo from "@/assets/x2y2.svg"
    import CoinbaseLogo from "@/assets/coinbase.svg"
    import { DownloadIcon, SearchCircleIcon, PhotographIcon } from '@heroicons/vue/outline'
    import NiftyWallsMetadata from '@/metadata.json'
    const props = defineProps(['id'])
    import NavigationBar from './NavigationBar.vue'
    import { useWalletStore2 } from '@/stores/WalletStore2'
    const wallet = useWalletStore2()
    
    const metadata = ref()
    const wall_owner = ref(false)
    const view = ref('thumbnails-600')

    async function get_metadata() {
      var m = await wallet.w_tokenURI(props.id)
      console.log(m)
      const json = atob(m.substring(29));
      const result = JSON.parse(json);
      //console.log(result)
      metadata.value = result
    }

    async function check_if_minted() {
      if (wallet.connected) {
        wall_owner.value = await wallet.w_ownerOf(props.id)
      }
    }

    function wallMetadata(id) {
      var res = {}
      res['attributes'] = []
      res['attributes'].push({trait_type: 'Turmite', value: NiftyWallsMetadata[id]['turmite'] })
      res['attributes'].push({trait_type: 'Pattern', value: NiftyWallsMetadata[id]['pattern'] })
      res['attributes'].push({trait_type: 'Effect', value: NiftyWallsMetadata[id]['fx_id']+1 })
      res['attributes'].push({trait_type: 'Background', value: NiftyWallsMetadata[id]['background'] })
      for (var c in NiftyWallsMetadata[id]['colors']) {
        res['attributes'].push({ trait_type: 'Color', value: NiftyWallsMetadata[id]['colors'][c]})
      }
      return res
    }

    wallet.init()
    check_if_minted()

    wallet.$subscribe((mutation, state) => {
      if (state.connected) {
        check_if_minted()
      }
    })
</script>


<style scoped>
</style>
