import HomePage from '@/components/HomePage'
import WallPage from '@/components/WallPage'
import AboutPage from '@/components/AboutPage'
import ContractPage from '@/components/ContractPage'
import MintPage from '@/components/MintPage'
import {createRouter, createWebHistory} from 'vue-router'

const  routes = [
        {
                path: '/',
                name: 'Home',
                component: HomePage
        },
        {
                path: '/contract/',
                name: 'Contract',
                component: ContractPage
        },
        {
                path: '/mint/',
                name: 'Mint',
                component: MintPage
        },
        {
                path: '/about/',
                name: 'About',
                component: AboutPage
        },
        {
                path: '/wall/:id',
                name: 'Wall',
                component: WallPage,
                props: true
        }
]

export default createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
})