<template>
          <div v-for="[hash, status, block, token] in txList()" v-bind:key="hash" class="">
            <router-link :to="'/wall/' + token" 
            class="w-36 h-36 ml-0 mr-2 my-1 text-gray-500 rounded-xl overflow-hidden bg-gray-100 border-0 border-gray-300 float-left bg-cover background-white "
            :style="'background-image: url(https://niftywalls.s3.amazonaws.com/crops/' + token + '.png);' "
            >
            <div v-show="status=='Pending'" class="inline-block align-middle h-36 w-36 text-gray-500">
              <ClockIcon class="w-8 h-8"/>
            </div>
            </router-link>
          </div>
</template>


<script setup>
    import { ClockIcon } from '@heroicons/vue/solid'
    const props = defineProps(['txDict', 'maxItems'])

    function txList() {
        var items = Object.keys(props.txDict).map(function(key) {
          return [
            key, 
            props.txDict[key]['status'], 
            props.txDict[key]['block'], 
            props.txDict[key]['token']
            ];
        });

        // Sort the array based on the second element
        items.sort(function(first, second) {
            // console.log('x',first[2], second[2], 'y')
            if (second[2] > 0 && first[2] > 0) {
                return second[2] - first[2]
            } 
            if (!second[2]) {
                return 1  
            }
            if (!first[2]) {
                return -1  
            }
            return 0
        });
        
        return props.maxItems ? items.slice(0,props.maxItems) : items ;
    }
</script>