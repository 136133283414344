<template>
<NavigationBar />
<div v-if="wallet.connected">
    <div class="main-content">
      <div class="mx-auto px-4 sm:px-6 lg:px-16">
        <div class="max-w-lg mx-auto rounded-lg overflow-hidden lg:max-w-none lg:flex">
          <div class="flex-1 px-0">
            
            <h3 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">{{ wallet.w_status.tokenCount }}/{{ wallet.w_status.totalSupply }} Walls minted.</h3>

            <p><b>{{ wallet.w_status.mintedToday }}/{{ wallet.w_status.mintsPerDay }} Walls</b> minted during the current period. 
              <br/>Only {{ wallet.w_status.mintsPerDay }} Walls can be minted every 24 hours.
            	Minting starts again after the 24h period ends.
            	</p>
            	<p v-if="mintPrice() == -1" >Next period starts {{ nextPeriodStart() }}.</p>
            <div class="mt-8">
              <div class="flex items-center">
                <h4 class="flex-shrink-0 pr-4 tracking-wider font-semibold uppercase">NFT friends</h4>
                <div class="flex-1 border-t-2 border-gray-200 mr-4"></div>
              </div>
              <p class="">If you hold one of the following NFTs you may be eligible for a free mint.</p>
              <div v-for="f in wallet.Friends">
              <p> 
                <CheckCircleIcon v-if="f.canMint" class="w-6 h-6 mb-1 inline-block text-green-700"/> 
                <MinusCircleIcon v-else class="w-6 h-6 mb-1 inline-block text-red-300"/> 
                {{f.name }} (free mints left: {{f.mints}}) 
              	<a :href="f.url" target="_new"><ExternalLinkIcon class="w-4 h-4 mb-1 inline-block" /></a>

                <span v-if="(mintPrice() > -1) && f.canMint"
                    class="" >
                    <button
                        class="items-center justify-center ml-4 px-2 py-1 border border-transparent rounded-md text-white bg-green-800 hover:bg-green-900 text-sm"  
                        @click="wallet.w_mintAsFriend(f.addr)"><GiftIcon class="w-5 h-5 mr-2 pb-1 inline-block" />Mint for free, friend
                    </button>
                </span>

              </p>
              </div>
              <div class="text-sm text-gray-700">(*) Each wallet can only clam a free mint <u>once</u>, regardless of how many eligible NFTs it may hold.</div>
            </div>
          </div>
          <div class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:px-12 rounded-lg">
            <p class="text-lg font-medium text-gray-900">Current Price</p>
            <div class="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
              <span> {{ currentPeriodPrice() }} </span>
              <span class="ml-3 text-xl font-medium text-gray-500"> ETH </span>
            </div>
            <p class="mt-4 text-sm">
              {{ wallet.w_status.mintedToday }}/{{ wallet.w_status.mintsPerDay }} Walls minted in current period.
            </p>
            <div class="mt-6">
              <div v-if="mintPrice() > -1"
                    class="" >
                    <button
                    	class="items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"  
                    	@click="wallet.w_mint">Mint
                    </button>
              </div>
                <div v-else
                    class="">
                    <button class="items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-300 hover:bg-gray-300">No mints available</button>
                </div>
            </div>
            <div class="mt-4 text-sm">
              <!-- a href="#" class="font-medium text-gray-900">
                Get a free sample
                <span class="font-normal text-gray-500"> (20MB) </span>
              </a -->
            </div>
          </div>
        </div>
      </div>
    </div>
  
  <div class="mx-auto px-4 sm:px-6 lg:px-16">
    <div class="text-2xl font-extrabold text-gray-900 sm:text-3xl py-8">Recently minted</div>
    <WallsListComponent :walls="wallet.lastMints"/>
  </div>
</div>
<div v-else><NoWallet /></div>
</template>

<script setup>
	import dayjs from 'dayjs'
    import NavigationBar from './NavigationBar.vue'
    import WallsListComponent from '@/components/WallsListComponent.vue'
    import NoWallet from '@/components/NoWallet.vue'

    import { HeartIcon, CheckCircleIcon, MinusCircleIcon, GiftIcon } from '@heroicons/vue/solid'
    import { ExternalLinkIcon } from '@heroicons/vue/outline'

    import { useWalletStore2 } from '@/stores/WalletStore2'
    const wallet = useWalletStore2()
    wallet.init()

    function tsToDate(ts) {
        const d = dayjs.unix(ts)
        return d.format('YYYY-MM-DD HH:mm')
    }

    function currentPeriodPrice() {
        return (wallet.w_status.price_eth*1).toFixed(3)
    }

    function mintPrice() {
    	if (
            ( wallet.w_status.mintedToday == wallet.w_status.mintsPerDay) &&
            ( (wallet.w_status.mintPeriodStart+86400)*1000 > Date.now() )
        ) { 
    		return -1
    	} else {
    		return (wallet.w_status.price_eth*1).toFixed(3)
    	}
    }

    function nextPeriodStart() {
        const ts = wallet.w_status.mintPeriodStart
        //const now = Date.now() / 1000
        if (!ts) {
            return '-'
        } else {
            return tsToDate(ts + 60*60*24)
        }
    }

</script>