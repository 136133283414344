<template>
<nav class="navbar">
  <div class="mx-auto px-4 sm:px-6 lg:px-16">
    <div class="flex justify-between h-16">
      
      <div class="flex">
        <div class="flex-shrink-0 flex items-center">
          <router-link to="/"       class=""><img class="block h-8 w-auto" src="/NiftyWalls-logo-full@2x.png" alt="Workflow">
          </router-link>
          
        </div>
        <div class="nav-group">
          <router-link to="/about/" class="nav-item">About</router-link>
          <router-link to="/mint/"  class="nav-item">Mint</router-link>
          <div class="nav-item">&mdash;</div>
          <a href="https://opensea.io/collection/niftywalls" target="_new" class="nav-item">OpenSea</a>
          <a href="https://looksrare.org/collections/0x1718aF1613d86e21488A9D985a586AE9c5dBD62E" target="_new" class="nav-item">LooksRare</a>
          <a href="https://x2y2.io/collection/niftywalls/items" target="_new" class="nav-item">X2Y2</a>
          <a href="https://nft.coinbase.com/collection/ethereum/0x1718af1613d86e21488a9d985a586ae9c5dbd62e" target="_new" class="nav-item">Coinbase</a>
          <div class="nav-item">&mdash;</div>
          <a href="https://twitter.com/niftywalls" target="_new" class="nav-item">Twitter</a>
          <a href="https://discord.gg/sP3EcyC5dV" target="_new" class="nav-item">Discord</a>
          <div class="router-link-active"></div>
        </div>
      </div>

        <!-- Profile dropdown -->
        <div class="ml-3 relative nav-group">
          
          <div class="nav-group">
            <a v-if="wallet.address" class="nav-item" aria-expanded="false" aria-haspopup="true" @click="user_menu=!user_menu" href="#">
              <span class="sr-only">Open user menu</span>
      
              <div v-if="wallet.ensName" class="router-link-active">{{ wallet.ensName }}</div>
              <div v-else class="router-link-active">{{ wallet.addressShort }}</div>

              <img v-if="wallet.ensAvatar" class="ml-2 h-6 w-6 rounded-full border-indigo-500" :src="wallet.ensAvatar" alt="">
              <div v-else class="ml-2 h-6 w-6 router-link-active"><UserCircleIcon /></div>
            </a>

              <a v-else class="nav-item" id="user-menu-button" aria-expanded="false" aria-haspopup="true" @click="connect" href="#">
                <span class="sr-only">Open user menu</span>
                <div class="text-gray-500 inline-flex whitespace-nowrap items-center px-1 py-1 block ">{{ connectStatus }}</div>
                <div ><UserCircleIcon  class="h-6 w-6 stroke-gray-500"/></div>
              </a>
          </div>
          
          <div v-show="user_menu && wallet.address" @mouseleave="user_menu=false"
          class="origin-top-right absolute right-0 mt-14 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none " role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
            <div class="block px-4 py-2 text-sm text-gray-700 border-b-2 border-gray-300" role="menuitem" tabindex="-1" id="user-menu-item-0"
            > {{ wallet._chain.name }} Network
            </div>
            <a :href="'https://etherscan.io/address/'+wallet.address" target="_new"
            class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-0"
            >{{ wallet.addressShort }} <ExternalLinkIcon class="inline-block font-gray-700 w-4 h-4 mb-1"/>
            </a>
            <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-1"
            ><span class="font-extrabold">Ξ</span> {{ wallet.ethBalance }}</a>
            <!-- a href="#" class="block px-4 py-2 text-sm text-gray-700" @click="wallet.disconnect">Disconnect</a -->
          </div>

      </div>

      
      <div class="-mr-2 flex items-center lg:hidden">
        <!-- Mobile menu button -->
        <button type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset" aria-controls="mobile-menu" aria-expanded="false"
        @click="mobile_menu =! mobile_menu" >

          <span class="sr-only">Open main menu</span>
          <MenuIcon v-if="!mobile_menu" class="h-6 w-6 block" />
          <XIcon v-if="mobile_menu" class="h-6 w-6 block" />
          
        </button>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div v-show="mobile_menu" class="lg:hidden" id="mobile-menu">
    <div class="pt-2 pb-2 space-y-1 border-t border-gray-200 items-center">
        <div class="nav-item-mobile inline-flex align-bottom"><HomeIcon  class="h-6 w-6 inline-block stroke-gray-500"/></div>
          <router-link to="/"       class="nav-item-mobile">Home</router-link>
          <router-link to="/about/" class="nav-item-mobile">About</router-link>
          <router-link to="/mint/"  class="nav-item-mobile">Mint</router-link>
          <div class="router-link-active"></div>        
    </div>
    <div class="pt-2 pb-3 space-y-1 pt-4 pb-3 border-t border-gray-200">
        <div v-if="wallet.address">
            
            <div class="nav-item-mobile router-link-active" aria-expanded="false">
              <img v-if="wallet.ensAvatar"  class="h-6 w-6 mr-2 rounded-full inline-block" :src="wallet.ensAvatar" alt="">
              <UserCircleIcon v-else        class="h-6 w-6 mr-2 inline-block" />
              <div v-if="wallet.ensName" class="inline-block">{{ wallet.ensName }}</div>
              <div v-else class="inline-block">{{ wallet.addressShort }}</div>
            </div>
            <br/>
            <div class="nav-item-mobile">Balance: Ξ {{ wallet.ethBalance }}</div>
            <br/>
            <a class="nav-item-mobile" :href="'https://etherscan.io/address/'+wallet.address" target="_new"
            >View on etherscan: {{ wallet.addressShort }} <ExternalLinkIcon class="inline-block font-gray-700 w-4 h-4 mb-1"/>
            </a>

        </div>

        <div v-else class="flex-shrink-0">
          <a class="nav-item-mobile" id="user-menu-button" aria-expanded="false" aria-haspopup="true" @click="connect" href="#">
            <span class="sr-only">Open user menu</span>
            <UserCircleIcon  class="inline-block h-6 w-6 mr-2 stroke-gray-500"/>
            <div class="text-gray-500 items-center px-1 py-1 inline-block">{{ connectStatus }}</div>
            
          </a>
        </div>



        <!-- div class="flex-shrink-0">
          <img v-if="wallet.ensAvatar" class="ml-2 h-6 w-6 rounded-full border-indigo-500" :src="wallet.ensAvatar" alt="">
          <div v-else class="ml-2 h-6 w-6 router-link-active"><UserCircleIcon /></div>
        </div>
        <div class="ml-3">
          <div v-if="wallet.ensName" class="router-link-active">{{ wallet.ensName }}</div>
          <div v-else class="router-link-active">{{ wallet.addressShort }}</div>
        </div -->
      
    </div>

    <div class="pt-2 pb-2 space-y-1 border-t border-gray-200 items-center">
      <div class="nav-item-mobile inline-flex align-bottom"><ShoppingBagIcon  class="h-6 w-6 inline-block stroke-gray-500"/></div>
      <a href="https://opensea.io/collection/niftywalls" target="_new" class="nav-item-mobile">OpenSea</a>
      <a href="https://looksrare.org/collections/0x1718aF1613d86e21488A9D985a586AE9c5dBD62E" target="_new" class="nav-item-mobile">LooksRare</a>
      <a href="https://x2y2.io/collection/niftywalls/items" target="_new" class="nav-item-mobile">X2Y2</a>
      <a href="https://nft.coinbase.com/collection/ethereum/0x1718af1613d86e21488a9d985a586ae9c5dbd62e" target="_new" class="nav-item-mobile">Coinbase</a>
    </div>
    <div class="pt-2 pb-3 space-y-1 pt-4 pb-3 border-t border-gray-200 items-center">
      <div class="nav-item-mobile inline-flex align-bottom"><ChatIcon  class="h-6 w-6 inline-block stroke-gray-500"/></div>
      <a href="https://twitter.com/niftywalls" target="_new" class="nav-item-mobile">Twitter</a>
      <a href="https://discord.gg/sP3EcyC5dV" target="_new" class="nav-item-mobile">Discord</a>
    </div>

  </div>

</nav>
</template>

<script setup>
  // import ConnectWallet from './ConnectWallet.vue'
  import { ref } from 'vue'
  import { useWalletStore2 } from '@/stores/WalletStore2'
  import { UserCircleIcon, BellIcon, MenuIcon, XIcon, ShoppingBagIcon, ChatIcon, HomeIcon } from '@heroicons/vue/outline'
  import { ExternalLinkIcon } from '@heroicons/vue/solid'
  const wallet = useWalletStore2()
  wallet.init()
  const user_menu = ref(false)
  const mobile_menu = ref(false)
  const connectStatus = ref('Connect wallet')

  async function connect() {
    connectStatus.value = 'Connecting...'
    await wallet.connect()
    connectStatus.value = 'Connect wallet'
  }

</script>
<style ></style>