<template>
<!-- This example requires Tailwind CSS v2.0+ -->

<div class="main-content">
      <div class="mx-auto px-4 sm:px-6 lg:px-16">
        <div class="max-w-lg mx-auto rounded-lg overflow-hidden lg:max-w-none lg:flex">
          <div class="flex-1 px-0">
            <h3 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">
              <ExclamationIcon class="w-8 h-8 mb-1 inline-block"/> Please connect a web3 wallet</h3>
            <p>You need a web3 wallet to use this page.</p>
            <button @click="connect" href="#" 
            class="inline-flex items-center justify-center px-5 py-3 border border-transparent font-normal rounded-md text-white bg-gray-600 hover:bg-gray-700  no-underline">
                {{ connectStatus }}
              </button>

          </div>
        </div>
      </div>
  </div>

</template>

<script setup>
  import { ref } from 'vue'
  import { ExclamationIcon } from '@heroicons/vue/solid'
  import { useWalletStore2 } from '@/stores/WalletStore2'
  const connectStatus = ref('Connect wallet')

  const wallet = useWalletStore2()
  
  async function connect() {
    connectStatus.value = 'Connecting...'
    await wallet.connect()
    connectStatus.value = 'Connect wallet'
  }
</script>