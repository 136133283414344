<template>

<div class="px-0">
  <div class="flex flex-col">
    <div class="my-2 -mx-0 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle lg:px-4">
        <table class="min-w-full">
          <tbody v-for="[hash, status, block, token] in txList()" v-bind:key="hash">
            <tr class="">
              <td class="whitespace-nowrap py-1 pl-4 text-sm font-medium text-gray-500">
                <div v-show="status=='Pending'" class="h-4 w-4 text-orange-400"><ClockIcon/></div>
                <div v-show="status=='Success'" class="h-4 w-4 text-green-700" ><CheckCircleIcon/></div>
              </td>
              <td class="whitespace-nowrap py-1 pl-4 text-sm font-medium text-gray-500">{{ hash }}</td>
              <td class="whitespace-nowrap py-1 px-3 text-sm font-medium text-gray-500">{{ block }}</td>
              <td class="whitespace-nowrap py-1 text-sm font-medium text-gray-500"><router-link :to="'/wall/' + token ">{{ token }}</router-link></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

</template>


<script setup>
    const props = defineProps(['txDict', 'maxItems'])

    import { ClockIcon, CheckCircleIcon } from '@heroicons/vue/solid'


    function txList() {
        var items = Object.keys(props.txDict).map(function(key) {
          return [
            key, 
            props.txDict[key]['status'], 
            props.txDict[key]['block'], 
            props.txDict[key]['token']
            ];
        });

        // Sort the array based on the second element
        items.sort(function(first, second) {
            // console.log('x',first[2], second[2], 'y')
            if (second[2] > 0 && first[2] > 0) {
                return second[2] - first[2]
            } 
            if (!second[2]) {
                return 1  
            }
            if (!first[2]) {
                return -1  
            }
            return 0
        });
        
        return props.maxItems ? items.slice(0,props.maxItems) : items ;
    }
</script>
