<template>
  <NavigationBar />

  <ContractStatus />

</template>

<script setup>
    import NavigationBar from './NavigationBar.vue'
    import ContractStatus from '@/components/ContractStatus.vue'
    console.log('NODE_ENV', process.env.NODE_ENV)
</script>


<style scoped>
</style>
