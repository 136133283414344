<template>
  <NavigationBar />
  <div class="safe-notch bg-gray-100">
  <div class="mx-0 px-8 sm:px-8 lg:px-16 lg:columns-2 lg:gap-6 main-content">
        <div class="max-w-2xl">
        <div class="flex">
          <div class="mt-0">
            <h1 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
         About me
            </h1>
          </div>
        </div>

        <p class="text-gray-500 mt-6">
        I'm vrypan, a.k.a Panayotis Vryonis. 

      My home is <a href="https://vrypan.net">vrypan.net</a>. My blog is <a href="https://blog.vrypan.net">blog.vrypan.net</a>. 
          The earliest blog posts there date back to 2003; I've been around for quite some time.
        </p>
        <p><a href="https://twitter.com/vrypan">You can follow me on twitter</a> too.
        </p>
    </div>

    <div class="max-w-2xl mt-8">
        <div class="flex">
          <div class="mt-0">
            <h1 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
         About the project
            </h1>
          </div>
        </div>
        <p>In a strange way, NiftyWalls is merging together things I've been passionate about during
          most of my life:
        </p>
        <p><b>Creative Commons</b> and how artists can make a living without limiting the freedom of others.
          <b>Cellular automata</b> and artificial "things" that seem to have a life of their own.
          <b>Long term storage</b> and data preservation.
          The simplicity of <b>web 1.0</b> in the 90s. The composability of the early <b>web2.0</b> and its social aspects later.
          The smart contracts and blockchains of <b>web 3.0.</b>
        </p>
        <p>I want to belive that if someone who has been following me for the last 30 years happened to visit 
          the NiftyWalls homepage without knowing who is behind it, they would say <i>"this has Panayotis written all over it"</i>.
        </p>

        <p>I don't consider NiftyWalls a done project. On the contrary, it's designed in a way that it didn't not have to be
        finished before launch. I expect that I'll keep working on it and use it as the foundation for new projects. </p>
        <p>But 
        <b>don't expect me</b> to commit on roadmaps, features, and deliverables. NiftyWalls is my little backyard garden 
        &mdash;I'll take care of it, work on it on my spare time, and put time and effort in things that
        nobody else but me will probably care about.
        </p>
    </div>

    <div class="max-w-2xl mt-8">
        <div class="flex">
          <div class="mt-0">
            <h1 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
         Technical &amp; Links
            </h1>
          </div>
        </div>
        <p><b>Smart contract:</b> {{ addr }} (contract.niftywalls.eth)
          <a :href="'https://etherscan.io/address/'+addr" target="_new">
            <ExternalLinkIcon class="inline-block font-gray-700 w-4 h-4 mb-1"/>
          </a>
        </p>
        <p ><b>Social:</b> <a href="https://discord.gg/sP3EcyC5dV">Discord</a> - 
          <a href="https://twitter.com/niftywalls">Twitter</a>
        </p>
        <p>
          <b>Marketplaces:</b> <a href="https://opensea.io/collection/niftywalls">OpenSea</a> - 
          <a href="https://looksrare.org/collections/0x1718aF1613d86e21488A9D985a586AE9c5dBD62E">LooksRare</a> - 
          <a href="https://x2y2.io/collection/niftywalls/items">X2Y2</a> - 
          <a href="https://nft.coinbase.com/collection/ethereum/0x1718af1613d86e21488a9d985a586ae9c5dbd62e">Coinbase</a>
        </p>
    </div>
</div>
</div>
<MintNow />
</template>

<script setup>
    import { ref } from 'vue'
    import NavigationBar from './NavigationBar.vue'
    import MintNow from './MintNow.vue'
    import { ExternalLinkIcon } from '@heroicons/vue/solid'

    const addr = ref(process.env.VUE_APP_WALLS_ADDR)
</script>


<style scoped>
</style>