import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import { ethers } from 'ethers';
import NiftyWallsAbi from '@/../../nifty-walls/artifacts/contracts/NiftyWalls.sol/NiftyWalls.json'
import WhitelistAbi from '@/../../nifty-walls/artifacts/contracts/Whitelist.sol/Whitelist.json'

const providerOptions = {
  /* See Provider Options Section */
}

var provider
var niftyWalls
// const wallsAddr ='0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512'
// const wallsAddr ='0xeEE2782D088fE36f55C4Efa68Cfd87092415A8FB'
const wallsAddr = process.env.VUE_APP_WALLS_ADDR
// const whitelistAddr = process.env.VUE_APP_WHITELIST_ADDR
const toast = useToast()

console.log('WALLS_ADDR', process.env.VUE_APP_WALLS_ADDR )

var Friends = [
        {
          name: 'Punkscapes',
          // addr: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512', mainnet
          // addr: '0x2d39B1305e5F8A3bcb89722B6cE37eD3f825Caff', //rinkeby
          addr: process.env.VUE_APP_PUNKSCAPE_ADDR,
          url:  'https://punkscape.xyz',
          mints: 0,
          canMint: false
        },
        {
          name: 'OG by Tank',
          addr: '0xb9b2580d902811546C5C5A4a26906Af183360b7c',
          url: 'https://nfttank.github.io/OG/',
          mints: 0,
          canMint: false

        },
        {
          name: 'OnChainBirds',
          addr: '0xBE82b9533Ddf0ACaDdcAa6aF38830ff4B919482C',
          url: 'https://onchainbirds.com/',
          mints: 0,
          canMint: false

        },
      ]
const abi = [
    // Read-Only Functions
    "function balanceOf(address owner) view returns (uint256)",
    "function symbol() view returns (string)",
    // Authenticated Functions
    "function transferFrom(address from, address to, uint256 tokenId) returns (bool)",
    // Events
    "event Transfer(address indexed from, address indexed to, uint amount)"
];

export const useWalletStore2 = defineStore('WalletStore2', {
  state: () => {
    return {
      address: null,
      ensName: null,
      ensAvatar: null,
      balance: null,
      whitelistAddr: null,
      wallsBalance: 'xxx',
      connected: false,
      chainId: false,
      w_status: {},
      tx: {},
      myWalls: [],
      lastMints: [],
      Friends: Friends,
      mintAsFriendOf: null,
      /* _chain: {
        id: 31337,
        name: 'Hardhat'
      },*/
      _chain: {
        id: process.env.VUE_APP_CHAIN_ID,
        name: process.env.VUE_APP_CHAIN_NAME
      },
    }
  },
  actions: {
    async dbg() {
      console.log('Debug start')
      let provider = ethers.providers? new ethers.providers.Web3Provider(window.ethereum) : null
      const network = await provider.getNetwork()
      const chainId = network.chainId
      console.log('  Chain ID = ' + chainId)
      const signer = provider.getSigner()
      const address = await signer.getAddress()
      console.log('  Address = ' + address)
      const balance = await provider.getBalance(address)
      console.log('  Balance = ' + balance)
      console.log('Debug end')
    },

    async checkWnf() {
      var whitelist = new ethers.Contract(this.whitelistAddr, WhitelistAbi['abi'], provider)
      this.mintAsFriendOf = null
      for(var i in Friends) {
        try {
          var mints = await whitelist.getEntries(Friends[i]['addr'])
          this.Friends[i]['mints'] = mints? mints.toNumber() : 0
          var canMint = await whitelist.isWhitelisted(Friends[i]['addr'], this.address) 
          this.Friends[i]['canMint'] = canMint
          if (canMint) {
            this.mintAsFriendOf = Friends[i]['addr']
          }
        } catch(err) {
          console.log(err)
        }
        // console.log(Friends[i]['addr'], this.Friends[i]['mints'], this.Friends[i]['canMint'])
      }
    },

    async chkTokenBalance(contractAddr) { // Is this used?
      // const contractAddr = '0x51Ae5e2533854495f6c587865Af64119db8F59b4'
      var contract = new ethers.Contract(contractAddr,abi, provider)
      const bal = await contract.balanceOf(this.address)
      console.log('Token balance = ' + bal)
    },

    async w_update_status() {
      var niftyWalls = new ethers.Contract(wallsAddr, NiftyWallsAbi['abi'], provider)
      this.whitelistAddr                = await niftyWalls.whitelist()
      this.w_status['price']            = await niftyWalls.price()
      this.w_status['price_eth']        = ethers.utils.formatEther(this.w_status['price'])
      this.w_status['mintsPerDay']      = ( await niftyWalls.mintsPerDay() ).toNumber()
      this.w_status['mintPeriodStart']  = ( await niftyWalls.mintPeriodStart() ).toNumber()
      this.w_status['mintedToday']      = ( await niftyWalls.mintedToday() ).toNumber()
      this.w_status['totalSupply']      = ( await niftyWalls.totalSupply() ).toNumber()
      this.w_status['tokenCount']       = await niftyWalls.mintedTokens() 

      if ((this.w_status['mintPeriodStart']+86400)*1000 < Date.now()){
        this.w_status['mintedToday'] = 0
      }      
      await this.checkWnf()
      await this.w_tokensMintedLast(8)
    },

    async w_tokenURI(id) {
      var niftyWalls = new ethers.Contract(wallsAddr, NiftyWallsAbi['abi'], provider)
      return await niftyWalls.tokenURI(id)      
    },

    async w_ownerOf(id) {
      var niftyWalls = new ethers.Contract(wallsAddr, NiftyWallsAbi['abi'], provider)
      try {
        const res = await niftyWalls.ownerOf(id)
        console.log('ownerOf', res)
        return res
      } catch (err) {
        console.log('ownerOf', false)
        return false
      }
    },

    async w_my_tokens() {
      if (this.address && this.connected) {
        const niftyWalls = new ethers.Contract(wallsAddr, NiftyWallsAbi['abi'], provider)
        const filter = niftyWalls.filters.Transfer(null, this.address)
        filter.fromBlock = provider.getBlockNumber().then((b) => b - 10000);
        filter.toBlock = "latest";
        // const tokens = await niftyWalls.filters.Transfer(null, this.address)
        provider.getLogs(filter).then((logs) => {
          // console.log(logs)
          for (l in logs) {
            console.log(l)
            var id = l['topics'][3]
            if (!this.myWalls.includes(id) ) {
              this.myWalls[id] = {}
            }
          }
        })
        for (w in this.myWalls) {
          console.log("My Wall = ", w)
        }
      }
    },

    async w_tokensMintedLast(count){
      const niftyWalls = new ethers.Contract(wallsAddr, NiftyWallsAbi['abi'], provider)
      const total = await niftyWalls.mintedTokens()
      const start = total-1 
      const end = (start-count >0) ? start-count : 0
      var lm = []
      for (let i=start; i>end; i-- ) {
        lm[start-i] = await niftyWalls.tokenByIndex(i)
      }
      this.lastMints = lm
    },

    async w_mint() {
      const nonce = await provider.getTransactionCount(this.address, 'latest') + 1; //get latest nonce
      console.log('Nonce = ' + nonce)
      const signer = provider.getSigner()
      const niftyWalls = new ethers.Contract(wallsAddr, NiftyWallsAbi['abi'], signer)
      const x = await niftyWalls.mint( {value:this.w_status['price']} )
      const receipt = await provider.waitForTransaction(x['hash'])
      this.tx[ x['hash'] ] = {
        status: 'Pending',
        block:  null,
        token: null
      }
      /*
      console.log('Receipt logs = ' + receipt.logs)
      console.log("Mint returned = ", x)
      if (!x[0]) {
        console.log("The hash of your transaction is: " +  x[1] )
      } else {
        console.log("Something went wrong when submitting your transaction:",x[0])
      }
      */
    },

    async w_mintAsFriend(contractAddr) {
      const nonce = await provider.getTransactionCount(this.address, 'latest') + 1; //get latest nonce
      console.log('Nonce = ' + nonce)
      const signer = provider.getSigner()
      const niftyWalls = new ethers.Contract(wallsAddr, NiftyWallsAbi['abi'], signer)
      const x = await niftyWalls.mintAsFriend(contractAddr, {gasLimit: 190000})
      const receipt = await provider.waitForTransaction(x['hash'])

      this.tx[ x['hash'] ] = {
        status: 'Pending',
        block:  null,
        token: null
      }
      /*
      console.log('Receipt logs = ' + receipt.logs)
      console.log("Mint returned = ", x)
      if (!x[0]) {
        console.log("The hash of your transaction is: " +  x[1] )
      } else {
        console.log("Something went wrong when submitting your transaction:",x[0])
      }*/
    },

    async connect(){
      console.log('Connect')
      if (typeof window.ethereum === 'undefined') {
        toast.error("You need Metamask installed.",
            {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false
            })
        return
      }
      if (provider) {
          const network = await provider.getNetwork()
          const chainId = network.chainId
          console.log('Chain ID = ' + chainId)
          console.log('Network Name = ' + network.name)
          this.chainId = chainId

          if (chainId != this._chain['id']) {
            toast.error("You must connect to the "+this._chain['name'] + " network",
            {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false
            })
            return ;
          }

          await provider.send('eth_requestAccounts', [])
          const signer = provider.getSigner()
          const address = await signer.getAddress()
          const balance = await provider.getBalance(address)

          if (chainId == 1) {
            const ensName = await provider.lookupAddress(address) 
            const ensAvatar = ensName ? await provider.getAvatar(ensName) : null
            this.ensName = ensName
            this.ensAvatar = ensAvatar
            console.log('ENS Name:', ensName)
            console.log('ENS Avatar:', ensAvatar)
          }
          
          this.address = address
          this.balance = balance
          this.connected = true
          this.w_update_status()
        } else {
          this.address = null
          this.ensName = null
          this.ensAvatar = null
          this.balance = null
          this.connected = false
        }
    },

    handleNetworkChange(newNetwork) {
      // provider = null
      if (newNetwork.chainId == this._chain['id']) {
        toast.info("Network changed to "+this._chain['name'] + ".",
              {
                position: "bottom-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
              })
      } else {
        toast.error("Network changed. You must be on the "+this._chain['name'] + " network",
            {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false
            })
      }
      // this.init()
      this.disconnect()
    },

    init(){
      // console.log('Init')
      // console.log('Provider ' + (provider? 'already set':'not set') )
      
      if (typeof window.ethereum === 'undefined') {
        return
      }
      if (!provider) {
        provider = ethers.providers? new ethers.providers.Web3Provider(window.ethereum, "any") : null
        if (provider) {
          provider.on("network", (newNetwork, oldNetwork) => {
              // When a Provider makes its initial connection, it emits a "network"
              // event with a null oldNetwork along with the newNetwork. So, if the
              // oldNetwork exists, it represents a changing network
              if (oldNetwork) {
                  this.handleNetworkChange(newNetwork)
              }
          });
          provider.provider.on('accountsChanged', this.connect )
          this.w_update_status()
          niftyWalls = new ethers.Contract(wallsAddr, NiftyWallsAbi['abi'], provider)
          niftyWalls.on("Transfer", (from, to, value, event) => {
            if (from == '0x0000000000000000000000000000000000000000') {
               this.tx[ event['transactionHash']] = {
                status: 'Success',
                block:  event['blockNumber'],
                token: value.toNumber()
               }
              
              /* console.log({
                me: this.address,
                from: from,
                to: to,
                value: value.toNumber(),
                data: event
              }) */
              this.w_update_status()
            }
          }) 
        }
      }
    },

    disconnect(){
      console.log('Disconnect')
      this.address = null
      this.balance = null
      this.connected = false
    }
  },

  getters: {
    addressShort() {
      if (this.connected && this.address) {
        return this.address.substr(0,6)+ "..." + this.address.substr(this.address.length - 4)
      } else {
        return ''
      }
    },
    ethBalance(state) {
      return (state.connected && state.balance)? (ethers.utils.formatEther(state.balance) * 1).toFixed(3) : ''
    }
  }
}) 